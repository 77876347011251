<template>
  <v-container fluid :key="tiertype">
    <v-row>
      <v-col cols="12" sm="6" md="12">
        <listitemspage
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :qTotal="Qtotal"
          :PageResponse="'tiers_sit_page'"
          :AllResponse="'tiers_sit_all'"
          :TotalResponse="'totals_sit'"
          :title="
            tiertype == 1
              ? 'Situation des Fournisseurs'
              : 'Situation des Clients'
          "
          :headers="headers"
          :field="'SITUATION_GL'"
          :filename="tiertype == 1 ? 'Fournisseurs' : 'Clients'"
          :sheetname="tiertype == 1 ? 'Fournisseurs' : 'Clients'"
          :showedit="false"
          @rowselect="TierChange"
          @hrf="ShowList"
          :Add="false"
          :del="false"
          :TypeScope="tiertype"
          :byexercice="true"
          :byperiode="true"
          :list_options="false"
          :btn1="{ text: 'dettes', icon: 'mdi-cash-minus' }"
          :btn2="{ text: 'creances', icon: 'mdi-cash-refund' }"
          @click1="dettes_creances('LT')"
          @click2="dettes_creances('GT')"
          :key="tiertype"
          :header_print="dettes_headers"
          @periode_change="periode_change"
          @exercice_change="exercice_change"
          :periode="true"
        >
        </listitemspage>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>

    <formlist
      :title="title"
      :subtitle="subtitle"
      :list="list"
      :showform="!isListClose"
      :listheaders="listheaders"
      :key="fl"
      @close="closeListForm"
      :width="width"
      :Total="true"
      :master="true"
      :kid="kid"
      @open="OpenDetail"
      :orientation="orientation"
      :laoding="loading"
    >
    </formlist>
  </v-container>
</template>

<script>
function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
const listitemspage = () => import("../components/ListItemsPages.vue");
const formlist = () => import("../components/FormList.vue");

import SIT_TIERS from "../graphql/Tier/SIT_TIERS.gql";
import SIT_TIERS_TOTALS from "../graphql/Tier/SIT_TIERS_TOTALS.gql";
import SIT_TIERS_ALL from "../graphql/Tier/SIT_TIERS_ALL.gql";
import SIT_TIER_DETAIL from "../graphql/Tier/SIT_TIER_DETAIL.gql";
import ALLCMDS from "../graphql/Commande/ALLCMDS.gql";
import ALLMVM from "../graphql/Mouvment/ALLMVM.gql";
import ALLFACT from "../graphql/Facture/ALLFACT.gql";
import ALLPAYE from "../graphql/Paiement/ALLPAYE.gql";
import FACT from "../graphql/Facture/FACT.gql";
import MVM from "../graphql/Mouvment/MVM.gql";
import { doc_template } from "print/template.js";
import { myBL } from "print/data.js";
import { datefr } from "@/utils/functions.js";
export default {
  components: {
    listitemspage,
    formlist,
  },
  props: {
    tiertype: Number,
  },
  data: () => ({
    klist: 0,
    orientation: "p",
    width: "1000px",
    title: "",
    isListClose: true,
    list: [],
    kid: "id",
    act_col: null,
    loading: false,
    listheaders: [],
    sitheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
        sortable: false,
      },
      {
        text: "Date operation",
        value: "date_opr",
        slot: "date",
        selected: true,
        sortable: false,
      },
      {
        text: "Reglé/Lettré",
        value: "ttc_paye",
        align: "end",
        slot: "cur33",
        selected: false,
        sortable: false,
      },
      {
        text: "Reste",
        value: "reste",
        align: "end",
        slot: "cur33",
        selected: false,
        sortable: false,
      },
      {
        text: "Reference",
        value: "nodoc",
        selected: true,
        sortable: false,
      },
      {
        text: "Operation",
        value: "opr",
        slot: "checked",
        column: "cloture",
        valeur: true,
        selected: true,
        sortable: false,
      },

      {
        text: "Mode Paiement",
        value: "modepaiement",
        selected: true,
        sortable: false,
      },
      {
        text: "Debit",
        value: "debit",
        align: "end",
        slot: "cur33",
        selected: true,
        total: "debit",
        sortable: false,
      },
      {
        text: "Credit",
        value: "credit",
        align: "end",
        slot: "cur33",
        total: "credit",
        selected: true,
        sortable: false,
      },
      {
        text: "Situation",
        value: "situation",
        align: "end",
        slot: "cur22",
        selected: true,
        sortable: false,
      },
    ],
    cmdheaders: [
      {
        text: "Code",
        value: "nocmd",
        slot: "href",
        enum: "NOCMD",
        totlib: true,
        selected: true,
      },
      {
        text: "Ref.",
        value: "cmd_ref",
        enum: "CMD_REF",
        selected: true,
      },
      {
        text: "Date commande",
        value: "date_cmd",
        slot: "date",
        enum: "DATE_CMD",
        selected: true,
      },
      {
        text: "H.T",
        value: "mont_ht",
        align: "end",
        slot: "cur",
        selected: true,
        total: "mont_ht",
      },
      {
        text: "T.T.C.",
        value: "mont_ttc",
        align: "end",
        slot: "cur",
        selected: true,
        total: "mont_ttc",
      },
      {
        text: "Statut.",
        value: "statut_id",
        selected: false,
        hiden: true,
      },
      {
        text: "Avant le",
        value: "date_limite",
        slot: "date",
        selected: true,
        sortable: false,
      },
      {
        text: "Statut",
        value: "status_name",
        enum: "STATUS_NAME",
        align: "text-center",
        expire: true,
        selected: true,
      },
    ],
    mvmheaders: [
      {
        text: "Code",
        value: "nobon",
        slot: "href",
        totlib: true,
        selected: true,
      },
      {
        text: "N° BL",
        value: "nomvm",
        selected: true,
      },
      {
        text: "Ref.",
        value: "mvm_ref",
        selected: true,
      },
      {
        text: "Date mouvement",
        value: "mvm_date",
        slot: "date",
        selected: true,
      },
      {
        text: "Commande",
        value: "nocmd",
        selected: true,
        hiden: true,
      },
      {
        text: "H.T",
        value: "mont_ht",
        align: "end",
        slot: "cur",
        selected: true,
        width: "150",
        total: "mont_ht",
      },
      {
        text: "T.T.C.",
        value: "mont_ttc",
        align: "end",
        slot: "cur",
        selected: true,
        width: "150",
        total: "mont_ttc",
      },
      {
        text: "Ret.",
        value: "mont_ret",
        align: "end",
        slot: "cur",
        selected: true,
        width: "150",
        total: "mont_ret",
      },
      {
        text: "Réglé",
        value: "ttc_paye",
        align: "end",
        slot: "cur",
        hiden: true,
        total: "ttc_paye",
        width: "150",
        selected: true,
      },
      {
        text: "No Facture",
        value: "nofact",
        align: "text-center",
        expire: true,
        selected: true,
      },
      {
        text: "Statut",
        value: "status_name",
        enum: "STATUS_NAME",
        align: "text-center",
        expire: true,
        selected: true,
      },
    ],
    factheaders: [
      {
        text: "Code",
        value: "nofact",
        slot: "href",
        enum: "NOFACT",
        totlib: true,
        selected: true,
      },
      {
        text: "Date facture",
        value: "fact_date",
        slot: "date",
        enum: "FACT_DATE",
        selected: true,
      },
      {
        text: "Ref.",
        value: "fact_ref",
        enum: "FACT_REF",
        selected: true,
      },
      {
        text: "H.T",
        value: "mont_ht",
        align: "end",
        slot: "cur",
        selected: true,
        total: "mont_ht",
      },
      {
        text: "T.T.C.",
        value: "mont_ttc",
        enum: "MONT_TTC",
        align: "end",
        slot: "cur",
        total: "mont_ttc",
        selected: true,
      },
      {
        text: "Réglé",
        value: "ttc_paye",
        enum: "TTC_PAYE",
        align: "end",
        slot: "cur",
        total: "ttc_paye",
        selected: true,
      },
      {
        text: "Statut",
        value: "status_name",
        enum: "STATUS_NAME",
        align: "text-center",
        expire: true,
        selected: true,
      },
    ],
    payeheaders: [
      {
        text: "Code",
        value: "nopaye",
        slot: "href",
        enum: "NOPAYE",
        selected: true,
        totlib: true,
      },
      {
        text: "Opr",
        value: "operation",
        selected: true,
      },
      {
        text: "Mode Paiement",
        value: "type_paye",
        enum: "TYPE_PAYE",
        selected: true,
      },
      {
        text: "Ref.",
        value: "paye_ref",
        enum: "PAYE_REF",
        selected: true,
      },
      {
        text: "No Piece",
        value: "nocheque",
        enum: "NOCHEQUE",
        selected: true,
      },
      {
        text: "Date Piece",
        value: "date_cheque",
        slot: "date",
        enum: "DATE_CHEQUE",
        selected: true,
      },
      {
        text: "Date Operation",
        value: "paye_date",
        slot: "date",
        enum: "PAYE_DATE",
        selected: true,
      },

      {
        text: "Montant ",
        value: "montant",
        enum: "MONTANT",
        slot: "cur22",
        selected: true,
        align: "end",
        total: "montant",
      },
      {
        text: "Lettré ",
        value: "ttc_det",
        enum: "TTC_DET",
        slot: "cur22",
        selected: true,
        align: "end",
        total: "ttc_det",
      },
      {
        text: "Reste ",
        value: "reste",
        enum: "RESTE",
        slot: "cur22",
        selected: true,
        align: "end",
        total: "reste",
      },
      {
        text: "Statut",
        value: "status_name",
        enum: "STATUS_NAME",
        align: "text-center",
        selected: true,
      },
    ],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",

    tabs2: null,
    search: null,
    headers: [
      {
        text: "Code",
        value: "code_client",
        enum: "CODE_CLIENT",
        totlib: true,
        selected: true,
      },
      {
        text: "Nom",
        align: "start",
        value: "nom",
        enum: "NOM",
        selected: true,
      },
      {
        text: "Statut",
        value: "statuts_name",
        enum: "STATUTS_NAME",
        selected: false,
        slot: "chip",
        color: "color",
      },
      {
        text: "Type",
        value: "tier_type_name",
        width: "100",
        enum: "TIER_TYPE_NAME",
        selected: false,
      },
      {
        text: "Auxiliaire",
        value: "account_number",
        width: "100",
        enum: "ACCOUNT_NUMBER",
        selected: false,
      },

      {
        text: "Situation Init.",
        value: "situation_ini",
        enum: "SITUATION_INI",
        align: "end",
        slot: "href2",
        result: "situation_ini",
        selected: false,
      },
      {
        text: "Sit. Enl. Init.",
        value: "situation_bl_ini",
        enum: "SITUATION_BL_INI",
        align: "end",
        slot: "href2",
        result: "situation_bl_ini",
        hiden: true,
        selected: false,
      },
      {
        text: "Commande",
        value: "cmd_ttc",
        enum: "CMD_TTC",
        align: "end",
        slot: "href2",
        result: "cmd_ttc",
        selected: false,
      },
      {
        text: "Expédition",
        value: "val_tot_liv",
        enum: "VAL_TOT_LIV",
        align: "end",
        slot: "href2",
        result: "val_tot_liv",
        hiden: true,
        selected: true,
      },
      {
        text: "Retours",
        value: "val_tot_ret",
        enum: "VAL_TOT_RET",
        align: "end",
        slot: "href2",
        result: "val_tot_ret",
        hiden: true,
        selected: true,
      },
      {
        text: "Factures",
        value: "ttc_fact",
        enum: "TTC_FACT",
        align: "end",
        slot: "href2",
        result: "ttc_fact",
        selected: true,
      },
      {
        text: "Avoir",
        value: "ttc_fact_av",
        enum: "TTC_FACT_AV",
        align: "end",
        slot: "href2",
        result: "ttc_fact_av",
        selected: true,
      },
      {
        text: "Encais. Enl",
        value: "paye_enl",
        enum: "PAYE_ENL",
        align: "end",
        result: "paye_enl",
        slot: "href2",
        hiden: true,
        selected: true,
      },
      {
        text: "Encaissements",
        value: "paye_fact",
        enum: "PAYE_FACT",
        align: "end",
        result: "paye_fact",
        slot: "href2",
        selected: true,
      },

      {
        text: "Situation",
        value: "situation_fact",
        enum: "SITUATION_FACT",
        align: "end",
        result: "situation_fact",
        slot: "href3",
        selected: true,
      },
      {
        text: "Sit.BL",
        value: "situation_enl",
        enum: "SITUATION_ENL",
        align: "end",
        result: "situation_enl",
        slot: "href3",
        hiden: true,
        selected: true,
      },
      {
        text: "Sit. GL",
        value: "situation_gl",
        enum: "SITUATION_GL",
        align: "end",
        result: "situation_gl",
        slot: "href3",
        hiden: true,
        selected: true,
      },
    ],
    dettes_headers: [
      {
        text: "Code",
        value: "code_client",
        totlib: true,
        selected: true,
      },
      {
        text: "Nom",
        align: "start",
        value: "nom",
        selected: true,
      },
      {
        text: "Sit Ini.",
        value: "situation_ini",
        align: "end",
        slot: "cur",
        total: "situation_ini",
        selected: true,
      },
      {
        text: "Debit",
        value: "tot_fact",
        align: "end",
        slot: "cur",
        total: "tot_fact",
        selected: true,
      },

      {
        text: "Credit",
        value: "paye_fact",
        align: "end",
        total: "paye_fact",
        slot: "cur",
        selected: true,
      },

      {
        text: "Situation",
        value: "situation_fact",
        align: "end",
        slot: "cur",
        total: "situation_fact",
        selected: true,
      },
    ],

    fk: 0,
    kd: 100,
    fl: 200,

    doc: {},
    tier: {},
    docs_list: [],
    paye_bl: false,
    dd: "",
    df: "",
    subtitle: null,
  }),

  computed: {
    Qselect() {
      return SIT_TIERS;
    },
    Qselectall() {
      return SIT_TIERS_ALL;
    },
    Qtotal() {
      return SIT_TIERS_TOTALS;
    },
    tier_name() {
      return this.tiertype == 1 ? "Fournisseur" : "Client";
    },
  },
  watch: {},
  created() {},

  mounted() {
    this.$store.dispatch("Changed", true);
    this.exercice = this.$store.state.exercice;
    this.dd = this.exercice.toString() + "-01-01";
    this.df = this.$store.state.today;
    //si noir && autorisation ou blanc
    if (
      (myBL == "n" &&
        (this.$store.state.auth.includes("05021") ||
          this.$store.state.isadmin)) ||
      myBL == "b"
    ) {
      this.headers[6].hiden = myBL == "b";
      this.headers[8].hiden = false;
      this.headers[9].hiden = false;
      this.headers[12].hiden = myBL == "b";

      this.headers[15].hiden = myBL == "b";
      this.headers[16].hiden = myBL == "b";

      this.dettes_headers[3].value = "tot_mvm";
      this.dettes_headers[3].total = "tot_mvm";
      this.dettes_headers[4].value = "tot_paye";
      this.dettes_headers[4].total = "tot_paye";
      this.dettes_headers[5].value = "situation_gl";
      this.dettes_headers[5].total = "situation_gl";

      this.mvmheaders[8].hiden = myBL == "b";
      this.paye_bl = true;
    }
    this.headers[0].value =
      this.tiertype == 1 ? "code_fournisseur" : "code_client";
    this.headers[0].enum =
      this.tiertype == 1 ? "CODE_FOURNISSEUR" : "CODE_CLIENT";
    this.headers[8].text = this.tiertype == 1 ? "Receptions" : "Expédition";
    this.headers[13].text = this.tiertype == 1 ? "Paiements" : "Encaissements";
  },

  methods: {
    get_desc(list, colis_unit, unit) {
      let desc = "";
      if (list.length > 0) {
        var unique = list.map((elm) => elm.article_id).filter(onlyUnique);
        if (unique.length > 1) {
          unique.forEach((elm2) => {
            let l = list.findIndex((elm) => elm.article_id == elm2);
            if (l.length > 0) {
              desc = desc + " " + l[0].label + "\n";
              list
                .filter((elm) => elm.article_id == l[0].link_id)
                .forEach((element) => {
                  desc =
                    desc +
                    element.nb.toString() +
                    " " +
                    colis_unit +
                    (element.nb > 1 ? "s" : "") +
                    " / " +
                    element.qte +
                    " " +
                    unit +
                    " " +
                    "\n";
                });
            }
          });
        } else
          list.forEach((element) => {
            desc =
              desc +
              element.nb.toString() +
              " " +
              colis_unit +
              (element.nb > 1 ? "s" : "") +
              " / " +
              element.qte +
              " " +
              unit +
              " " +
              "\n";
          });
        if (list.length > 1 && unique.length == 1)
          desc =
            desc +
            "Soit " +
            list.reduce((a, b) => a + (b["nb"] || 0), 0) +
            " " +
            colis_unit +
            "s pour un total de " +
            list.reduce(
              (a, b) => a + (parseFloat(b["qte"]) * parseInt(b["nb"]) || 0),
              0
            ) +
            " " +
            unit;
      }
      return desc;
    },
    decimalDigits: function (value) {
      return typeof value == "number" ? value.toFixed(2) : 0;
    },
    async printfact(item) {
      let fact = {};
      let r = await this.requette(
        FACT,
        {
          where: { column: "ID", value: item.id },
        },
        "no-cache"
      );
      if (r) {
        fact = r.facts.data[0];

        let doctype = [];
        let title = "";
        if (fact.fact_type == 1 || fact.fact_type == 3) {
          title = "Facture ";
        }
        if (fact.fact_type == 2 || fact.fact_type == 4) {
          title = "Facture Avoir ";
        }
        doctype.push({
          lib: {
            content: title,
            styles: { fontStyle: "bold", fontSize: 20, textColor: [0, 0, 0] },
            colSpan: 2,
          },
        });
        doctype.push({
          lib: {
            content: "N° :",
            styles: { fontStyle: "normal", fontSize: 10 },
          },
          val: {
            content: fact.nofact,
            styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
          },
        });
        doctype.push({
          lib: {
            content: "Date : ",
            styles: { fontStyle: "normal", fontSize: 10 },
          },
          val: {
            content: datefr(fact.fact_date),
            styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
          },
        });
        doctype.push({
          lib: {
            content: "Réference:",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: fact.fact_ref
              ? fact.fact_ref
              : fact.cmd_ref
              ? fact.cmd_ref
              : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
        doctype.push({
          lib: {
            content: "Mode de Réglement:",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: fact.mode ? fact.mode : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });

        doctype.push({
          lib: {
            content: "Commandes Ref.:",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: fact.cmds_ref
              ? fact.cmds_ref
              : fact.cmds_no
              ? fact.cmds_no
              : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
        let tier = [];
        tier.push({
          lib: {
            content: fact.fact_type == 1 ? "Béneficiaire" : "Doit :",
            styles: { fontStyle: "normal", fontSize: 12, textColor: [0, 0, 0] },
            colSpan: 2,
          },
        });
        tier.push({
          lib: {
            content:
              (fact.forme_juridique ? fact.forme_juridique : "") +
              " " +
              fact.tier_name,
            styles: { fontStyle: "bold", fontSize: 12, textColor: [0, 0, 0] },
            colSpan: 2,
          },
        });
        tier.push({
          lib: {
            content: fact.adresse ? fact.adresse : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
            colSpan: 2,
          },
        });
        tier.push({
          lib: {
            content: "Tél :",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: fact.tel ? fact.tel : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
        tier.push({
          lib: {
            content: "Email :",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: fact.email ? fact.email : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
        tier.push({
          lib: {
            content: "RC  :",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: fact.RC ? fact.RC : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
        tier.push({
          lib: {
            content: "NIF  :",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: fact.NIF ? fact.NIF : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
        tier.push({
          lib: {
            content: "ART  :",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: fact.ART ? fact.ART : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
        tier.push({
          lib: {
            content: "NIS  :",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: fact.NIS ? fact.NIS : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
        let foot = [];
        foot.push({
          libelle: fact.fact_type == 1 ? "Total" : "Montant HT",
          value: this.numberWithSpace(this.decimalDigits(fact.mont_ht)),
        });

        if (fact.mont_tva > 0 && fact.fact_type != 1) {
          foot.push({
            libelle: "TVA",
            value: this.numberWithSpace(this.decimalDigits(fact.mont_tva)),
          });
        }
        if (
          fact.mont_ht > 0 &&
          fact.mont_tva == 0 &&
          fact.currency_id == 114 &&
          fact.fact_type != 1
        ) {
          foot.push({
            libelle: "TVA ",
            value: "EXONORE",
          });
        }

        if (fact.timbre > 0 && fact.fact_type != 1) {
          foot.push({
            libelle: "Droit de timbre (1%)",
            value: this.numberWithSpace(this.decimalDigits(fact.timbre)),
          });
        }
        if (fact.mont_exe_taxs > 0 && fact.fact_type != 1) {
          foot.push({
            libelle: "Exonoration de la TVA",
            value: {
              content:
                "-" +
                this.numberWithSpace(this.decimalDigits(fact.mont_exe_taxs)),
              styles: { fontStyle: "normal" },
            },
          });
        }
        if (fact.fact_type != 1)
          foot.push({
            libelle: "Montant TTC",
            value: {
              content: this.numberWithSpace(this.decimalDigits(fact.mont_ttc)),
              styles: { fontStyle: "bold" },
            },
          });

        let fact_detail = [];
        let k;
        if (fact.factdetails && fact.msq_origin == 0)
          fact.factdetails.forEach((element) => {
            let colis_json = [];
            if (element.colis_json) colis_json = JSON.parse(element.colis_json);
            //search retours
            let colis_json_retours = [];
            let i = fact.factmvms.findIndex(
              (elm) => elm.mvm_id == element.mvm_id
            );
            if (i >= 0) {
              fact.factmvms[i].retours
                .filter((elm) => elm.decision_ret == 3 && elm.statut_id == 11)
                .forEach((retour) => {
                  let j = retour.mvmdetails.findIndex(
                    (elm) => elm.mvm_det_id == element.id
                  );

                  if (j >= 0) {
                    let cj = JSON.parse(retour.mvmdetails[j].colis_json);

                    if (cj)
                      cj.forEach((element) => {
                        let p = colis_json_retours.findIndex(
                          (elm) =>
                            elm.article_id == element.article_id &&
                            elm.qte == element.qte
                        );
                        if (p >= 0)
                          colis_json_retours[p].nb =
                            colis_json_retours[p].nb + element.nb;
                        else {
                          colis_json_retours.push(element);
                        }
                      });
                  }
                });
            }

            colis_json.forEach((cj) => {
              let p = colis_json_retours.findIndex(
                (elm) => elm.article_id == cj.article_id && elm.qte == cj.qte
              );
              if (p >= 0) cj.nb = cj.nb - colis_json_retours[p].nb;
            });
            //end retours
            k = fact_detail.findIndex(
              (elm) =>
                elm.article_id == element.article_id &&
                elm.pu == element.pu &&
                elm.remise == element.remise
            );
            if (k >= 0) {
              fact_detail[k].qte = fact_detail[k].qte + element.qte;
              fact_detail[k].ht =
                (element.pu - (element.pu * element.remise) / 100) *
                fact_detail[k].qte;
              fact_detail[k].ht = Math.round(fact_detail[k].ht * 100) / 100;

              colis_json.forEach((cj) => {
                let p = fact_detail[k].colis_json.findIndex(
                  (elm) => elm.article_id == cj.article_id && elm.qte == cj.qte
                );
                if (p >= 0)
                  fact_detail[k].colis_json[p].nb =
                    fact_detail[k].colis_json[p].nb + cj.nb;
                else {
                  fact_detail[k].colis_json.push(cj);
                }
              });
              fact_detail[k].description = this.get_desc(
                fact_detail[k].colis_json.filter((elm) => elm.nb > 0),
                element.colis_unit,
                element.unit
              );
            } else {
              let desc = this.get_desc(
                colis_json.filter((elm) => elm.nb > 0),
                element.colis_unit,
                element.unit
              );
              fact_detail.push({
                id: element.id,
                article_id: element.article_id,
                produit_id: element.produit_id,
                code: element.code,
                label: element.product_name,
                pu: element.pu,
                remise: element.remise,
                qte: element.qte,
                mvm_id: element.mvm_id,
                ht:
                  (element.pu - (element.pu * element.remise) / 100) *
                  element.qte,
                tva_tx: element.tva_tx,
                comment: element.comment,
                description: desc,
                nocmd: element.nocmd,
                cmd_ref: element.cmd_ref,
                colis_json: colis_json.filter((elm) => elm.nb > 0),
              });
            }
          });
        let detail_extra = fact.factdetailsextra ? fact.factdetailsextra : [];

        if (detail_extra)
          detail_extra.forEach((element) => {
            fact_detail.push({
              id: element.id,
              code: element.code,
              label: element.label,
              produit_id: element.produit_id,
              nobon: "",
              mont_ttc: element.pu,
              pu: element.pu,
              qte: element.qte,
              remise: 0,
              ht: parseFloat((element.pu * element.qte).toFixed(2)),
              tva_tx: element.tva_tx,
              comment: element.comment,
            });
          });

        let detailheaders = [
          {
            text: "No",
            value: "no",
            selected: true,
            align: "center",
            width: 10,
          },
          {
            text: "Code",
            value: "code",
            selected: true,
            align: "left",
            width: 20,
          },
          {
            text: "Designation",
            value: "label",
            column: "article_id",
            selected: true,
            align: "left",
            width: "auto",
          },
          {
            text: "PU HT",
            value: "pu",
            slot: "cur",
            selected: true,
            width: 30,
          },
          {
            text: "Quantité",
            value: "qte",
            slot: "cur",
            align: "end",
            selected: true,
            width: 20,
          },
          {
            text: "Montant HT",
            value: "ht",
            slot: "cur",
            align: "end",
            selected: true,
            width: 30,
          },
        ];
        let lettre = [];
        lettre.push({
          libelle: {
            content:
              "Arrêtée la présente " + title.toLowerCase() + " à la somme de :",
            styles: { fontStyle: "normal", fontSize: 10 },
          },
        });
        lettre.push({
          libelle: {
            content: this.$lettre.NumberToLetter(fact.mont_ttc),
            styles: { halign: "left", fontStyle: "normal", fontSize: 10 },
          },
        });

        doc_template(
          this.$store,
          detailheaders,
          fact_detail,
          doctype,
          tier,
          foot,
          lettre,
          fact.note,
          title,
          fact.fact_type == 1 ? null : fact.compte,
          fact.fact_type == 1 ? false : true,
          "",
          null,
          "p",
          "",
          false,
          fact.statut_id == 14 &&
            (fact.fact_type == 2 || fact.fact_type == 3) &&
            fact.ttc_paye >= fact.mont_ttc
            ? "Reglée"
            : null
        );

        this.loading = false;
      }
    },
    async printMvm(item) {
      let mvm = {};
      let r = await this.requette(MVM, {
        where: { column: "ID", value: item.id },
        exp: 1,
      });
      if (r) {
        mvm = r.mvms.data[0];
        if (
          mvm.mvm_type == 1 ||
          mvm.mvm_type == 2 ||
          mvm.mvm_type == 3 ||
          mvm.mvm_type == 23 ||
          mvm.mvm_type == 4 ||
          mvm.mvm_type == 6 ||
          mvm.mvm_type == 7 ||
          mvm.mvm_type == 8 ||
          mvm.mvm_type == 9 ||
          mvm.mvm_type == 14 ||
          mvm.mvm_type == 15 ||
          mvm.mvm_type == 16 ||
          mvm.mvm_type == 17 ||
          mvm.mvm_type == 18 ||
          mvm.mvm_type == 19 ||
          mvm.mvm_type == 21 ||
          mvm.mvm_type == 22
        ) {
          let doctype = [];
          doctype.push({
            lib: {
              content:
                mvm.mvm_type == 1
                  ? "Bon de Reception"
                  : mvm.mvm_type == 2
                  ? "Bon de Retour Fournisseur"
                  : mvm.mvm_type == 3 && mvm.replace == 0
                  ? "Bon d'Expedition"
                  : mvm.mvm_type == 3 && mvm.replace == 1
                  ? "Bon de Restitution"
                  : mvm.mvm_type == 4
                  ? "Bon de Retour Client"
                  : mvm.mvm_type == 6 || mvm.mvm_type == 9
                  ? "Bon de Reception Interne"
                  : mvm.mvm_type == 7
                  ? "Bon de Sortie Matière"
                  : mvm.mvm_type == 8
                  ? "Bon de Réintégration"
                  : mvm.mvm_type == 14
                  ? "Bon de Reception NC"
                  : mvm.mvm_type == 15 || mvm.mvm_type == 21
                  ? "Bon de Sortie Magasin"
                  : mvm.mvm_type == 16 || mvm.mvm_type == 22
                  ? "Bon de Retour Magasin"
                  : mvm.mvm_type == 17 || mvm.mvm_type == 23
                  ? "ATTESTATION DE SERVICE FAIT"
                  : mvm.mvm_type == 18
                  ? "Bon d'Entrée Matière"
                  : mvm.mvm_type == 19
                  ? "Bon d'Entrée Produit"
                  : "",
              styles: {
                fontStyle: "bold",
                fontSize: mvm.mvm_type == 17 || mvm.mvm_type == 23 ? 14 : 20,
                textColor: [0, 0, 0],
                cellPadding: { bottom: 3 },
              },
              colSpan: 2,
            },
          });
          doctype.push({
            lib: {
              content: "N° :",
              styles: { fontStyle: "normal", fontSize: 10 },
            },
            val: {
              content: mvm.nobon,
              styles: {
                fontStyle: "normal",
                fontSize: 10,
                textColor: [0, 0, 0],
              },
            },
          });

          if (
            mvm.mvm_type == 1 ||
            mvm.mvm_type == 3 ||
            mvm.mvm_type == 23 ||
            mvm.mvm_type == 17 ||
            mvm.mvm_type == 19
          ) {
            doctype.push({
              lib: {
                content: "Date : ",
                styles: { fontStyle: "normal", fontSize: 10 },
              },
              val: {
                content: mvm.opr_date ? datefr(mvm.opr_date) : "",
                styles: {
                  fontStyle: "normal",
                  fontSize: 10,
                  textColor: [0, 0, 0],
                },
              },
            });
          }
          if (mvm.mvm_type == 7) {
            let ofs = "";
            mvm.order.forEach((element) => {
              ofs = ofs + element.no_order + "/";
            });
            doctype.push({
              lib: {
                content: "OFs :",
                styles: { fontStyle: "normal", fontSize: 10 },
              },
              val: {
                content: ofs,
                styles: {
                  fontStyle: "normal",
                  fontSize: 10,
                  textColor: [0, 0, 0],
                },
              },
            });
          }
          let tier = [];
          tier.push({
            lib: {
              content:
                mvm.mvm_type == 1 || mvm.mvm_type == 2 || mvm.mvm_type == 17
                  ? "Fournisseur :"
                  : mvm.mvm_type == 3 ||
                    mvm.mvm_type == 23 ||
                    mvm.mvm_type == 4 ||
                    mvm.mvm_type == 19
                  ? "Client"
                  : this.rcpt_prod
                  ? ""
                  : "Demandé Par :",
              styles: {
                fontStyle: "normal",
                fontSize: 12,
                textColor: [0, 0, 0],
              },
              colSpan: 2,
            },
          });
          tier.push({
            lib: {
              content:
                (mvm.forme_juridique ? mvm.forme_juridique + " " : "") +
                (mvm.tier_name ? mvm.tier_name : ""),
              styles: { fontStyle: "bold", fontSize: 12, textColor: [0, 0, 0] },
              colSpan: 2,
            },
          });
          tier.push({
            lib: {
              content:
                mvm.mvm_type <= 4 || mvm.mvm_type == 17 || mvm.mvm_type == 19
                  ? "Code :"
                  : this.rcpt_prod
                  ? ""
                  : "Matricule",
              styles: { fontStyle: "normal", fontSize: 9 },
            },
            val: {
              content: mvm.tier_code ? mvm.tier_code : "",
              styles: {
                fontStyle: "normal",
                fontSize: 9,
                textColor: [0, 0, 0],
              },
            },
          });
          if (mvm.tier) {
            tier.push({
              lib: {
                content: mvm.tier.adresse ? mvm.tier.adresse : "",
                styles: {
                  fontStyle: "normal",
                  fontSize: 9,
                  textColor: [0, 0, 0],
                },
                colSpan: 2,
              },
            });
            tier.push({
              lib: {
                content: "Tél :",
                styles: { fontStyle: "normal", fontSize: 9 },
              },
              val: {
                content: mvm.tier.tel ? mvm.tier.tel : "",
                styles: {
                  fontStyle: "normal",
                  fontSize: 9,
                  textColor: [0, 0, 0],
                },
              },
            });
            tier.push({
              lib: {
                content: "Email :",
                styles: { fontStyle: "normal", fontSize: 9 },
              },
              val: {
                content: mvm.tier.email ? mvm.tier.email : "",
                styles: {
                  fontStyle: "normal",
                  fontSize: 9,
                  textColor: [0, 0, 0],
                },
              },
            });
          }

          let foot = [];
          if (
            (mvm.mvm_type <= 4 || mvm.mvm_type == 17 || mvm.mvm_type == 23) &&
            (this.$store.state.auth.includes("01027") ||
              this.$store.state.isadmin)
          ) {
            if (mvm.mvm_type != 3) {
              foot.push({
                libelle: "Montant HT",
                value: this.numberWithSpace(mvm.mont_ht.toFixed(2)),
              });

              if (mvm.mont_tva > 0) {
                foot.push({
                  libelle: "TVA",
                  value: this.numberWithSpace(mvm.mont_tva.toFixed(2)),
                });
              }
              if (
                mvm.mont_ht > 0 &&
                mvm.mont_tva == 0 &&
                mvm.country_id == 13
              ) {
                foot.push({
                  libelle: "TVA ",
                  value: "EXONORE",
                });
              }
            }
            foot.push({
              libelle: "Montant TTC",
              value: {
                content: this.numberWithSpace(mvm.mont_ttc.toFixed(2)),
                styles: { fontStyle: "bold" },
              },
            });
          }
          let detailheaders = [
            {
              text: "No",
              value: "no",
              selected: true,
              align: "center",
              width: 10,
            },
            {
              text: "Code",
              value: "code",
              selected: true,
              align: "left",
              width: 20,
            },
            {
              text: "Compte",
              value: "compte",
              selected: true,
              align: "left",
              hiden: true,
              width: 20,
            },
            {
              text: "Désignation",
              value: "label",
              selected: true,
              align: "left",
              width: "auto",
            },
            {
              text: "Quantité",
              value: "qte",
              slot: "qte",
              width: 20,
              align: "end",
              selected: true,
            },
            {
              text: "Unité",
              value: "unit",
              width: 20,
              selected: true,
            },
          ];
          let lettre = [];
          let letter_h = [];
          if (mvm.mvm_type == 1) {
            if (mvm.mvmdetails) mvm.mvmdetails.sort(this.sortBy("compte"));
            detailheaders[2].hiden = false;
            let compte_list = mvm.mvmdetails.map((elm) => elm.compte);
            compte_list = compte_list.filter(onlyUnique);
            if (compte_list)
              compte_list.sort((a, b) => (a ? a.localeCompare(b) : ""));
            letter_h.push({
              head: "Compte",
              datakey: "compte",
              halign: "left",
            });
            letter_h.push({
              head: "Valeur",
              datakey: "value",
              halign: "right",
            });
            compte_list.forEach((element) => {
              let v = mvm.mvmdetails
                .filter((elm) => elm.compte == element)
                .reduce((a, b) => a + (b["ht"] || 0), 0);
              lettre.push({
                compte: {
                  content: element,
                  styles: { fontStyle: "normal", fontSize: 10 },
                },
                value: {
                  content: this.numberWithSpace(v.toFixed(2)),
                  styles: { fontStyle: "normal", fontSize: 10 },
                },
              });
            });
          }

          if (
            (mvm.mvm_type <= 4 || mvm.mvm_type == 17 || mvm.mvm_type == 23) &&
            (this.$store.state.auth.includes("01027") ||
              this.$store.state.isadmin)
          ) {
            let pu = mvm.mvm_type == 3 || mvm.mvm_type == 23 ? "pu_ttc" : "pu";
            let mont = mvm.mvm_type == 3 || mvm.mvm_type == 23 ? "total" : "ht";
            detailheaders.push({
              text: "PU ",
              value: pu,
              width: 15,
              align: "end",
              selected: true,
            });
            detailheaders.push({
              text: "Montant",
              value: mont,
              slot: "cur",
              width: 30,
              align: "end",
              selected: true,
            });

            let t;
            if (mvm.mvm_type == 17) {
              t =
                "Nous Soussigné, Monsieur : " +
                (mvm.preneur ? mvm.preneur : "") +
                " agissant en qualité de : " +
                (mvm.fonction ? mvm.fonction : "") +
                " avons vérifié les articles ayant fait l'objet de commande ci-dessus selon le detail suivant : ";
              lettre.push({
                libelle: {
                  content:
                    "Et avons constaté que les travaux ont été réellement fidèlement exécutés ",
                  styles: { fontStyle: "normal", fontSize: 10 },
                },
              });
              lettre.push({
                libelle: {
                  content: "En foi de quoi, nous signons cette attestation. ",
                  styles: { fontStyle: "normal", fontSize: 10 },
                },
              });
            }

            doc_template(
              this.$store,
              detailheaders,
              mvm.mvmdetails,
              doctype,
              tier,
              foot,
              lettre,
              mvm.notes,
              mvm.mvm_type == 1
                ? "Bon de Reception"
                : mvm.mvm_type == 3
                ? "Bon d'expédition"
                : mvm.mvm_type == 6 || mvm.mvm_type == 9
                ? "Bon de Reception Interne"
                : mvm.mvm_type == 7
                ? "Bon de Sortie Matière"
                : mvm.mvm_type == 8
                ? "Bon de Réintégration"
                : mvm.mvm_type == 14
                ? "Bon de Reception NC"
                : mvm.mvm_type == 15 || mvm.mvm_type == 21
                ? "Bon de Sortie Magasin"
                : mvm.mvm_type == 16 || mvm.mvm_type == 22
                ? "Bon de Retour Magasin"
                : mvm.mvm_type == 17 || mvm.mvm_type == 23
                ? "ATTESTATION DE SERVICE FAIT"
                : mvm.mvm_type == 18
                ? "Bon d'Entrée Magasin"
                : mvm.mvm_type == 19
                ? "Bon d'Entrée Produit"
                : "",
              null,
              null,
              null,
              t,
              mvm.mvm_type == 17 ? "l" : "p",
              letter_h
            );
          }
        }
      }
    },
    sortBy(prop) {
      return function (a, b) {
        return a[prop] > b[prop] ? -1 : 1;
      };
    },
    periode_change(dd, ff) {
      this.dd = dd;
      this.df = ff;
    },
    exercice_change(exercice) {
      this.exercice = exercice;
      this.dd = this.exercice.toString() + "-01-01";
      this.df = this.exercice.toString() + "-12-31";
    },
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    async dettes_creances(opr) {
      if (this.tiertype == 1) {
        this.dettes_headers[0].value = "code_fournisseur";
        this.dettes_headers[3].text = "Credit";
        this.dettes_headers[4].text = "Debit";
      }
      this.listheaders = this.dettes_headers;
      this.title = opr == "LT" ? "Liste des Dettes " : "Liste des Creances ";
      let v;
      if (this.paye_bl)
        v = {
          where: { column: "SITUATION_GL", operator: opr, value: 0 },
          orderBy: [{ column: "SITUATION_GL", order: "DESC" }],
          TypeScope: this.tiertype,
          date_debut: this.dd,
          date_fin: this.df,
        };
      else
        v = {
          where: { column: "SITUATION_FACT", operator: opr, value: 0 },
          orderBy: [{ column: "SITUATION_FACT", order: "DESC" }],
          TypeScope: this.tiertype,
          date_debut: this.dd,
          date_fin: this.df,
        };
      let r = await this.requette(SIT_TIERS_ALL, v);
      this.width = "1300px";
      if (r) this.list = r.tiers_sit_all;
      this.isListClose = false;
      this.fl++;
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },

    ShowList(item) {
      let w;
      let query;
      let result;
      let s = "situation_fact";
      this.width = "1400px";
      if (this.paye_bl) {
        s = "situation_gl";
      }
      this.kid = "id";
      this.act_col = item.col;
      switch (item.col) {
        case "situation_gl":
          this.title =
            "Situation du " +
            this.tier_name +
            " - " +
            item.nom +
            " : " +
            this.numberWithSpace(item.situation_gl.toFixed(2));

          this.listheaders = this.sitheaders;
          this.kid = "docid";
          w = {
            tier_id: item.id,
            tiertype: this.tiertype,
            facture: [0, 1],
            date_debut: this.dd,
            date_fin: this.df,
          };
          query = SIT_TIER_DETAIL;
          result = "sit_tier_detail";

          this.width = "1300px";
          this.orientation = "l";
          break;
        case "situation_fact":
          this.title =
            "Situation du " +
            this.tier_name +
            " - " +
            item.nom +
            " : " +
            this.numberWithSpace(item.situation_fact.toFixed(2));

          this.listheaders = this.sitheaders;
          this.kid = "docid";
          w = {
            tier_id: item.id,
            tiertype: this.tiertype,
            facture: [1],
            date_debut: this.dd,
            date_fin: this.df,
          };
          query = SIT_TIER_DETAIL;
          result = "sit_tier_detail";

          this.width = "1300px";
          this.orientation = "l";
          break;
        case "situation_enl":
          this.title =
            "Situation du " +
            this.tier_name +
            " - " +
            item.nom +
            " : " +
            this.numberWithSpace(item.situation_enl.toFixed(2));

          this.listheaders = this.sitheaders;
          this.kid = "docid";
          w = {
            tier_id: item.id,
            tiertype: this.tiertype,
            facture: [0],
            date_debut: this.dd,
            date_fin: this.df,
          };
          query = SIT_TIER_DETAIL;
          result = "sit_tier_detail";

          this.width = "1300px";
          this.orientation = "l";
          break;
        case "situation_bl_ini":
          this.title =
            "Situation du " +
            this.tier_name +
            " - " +
            item.nom +
            " : " +
            this.numberWithSpace(item.situation_bl_ini.toFixed(2));

          this.listheaders = this.sitheaders;
          this.kid = "docid";
          w = {
            tier_id: item.id,
            tiertype: this.tiertype,
            facture: [3],
            date_debut: this.dd,
            date_fin: this.df,
          };
          query = SIT_TIER_DETAIL;
          result = "sit_tier_detail";

          this.width = "1300px";
          this.orientation = "l";
          break;

        case "situation_ini":
          this.title =
            "Situation du " +
            this.tier_name +
            " - " +
            item.nom +
            " : " +
            this.numberWithSpace(item.situation_ini.toFixed(2));

          this.listheaders = this.sitheaders;
          this.kid = "docid";
          w = {
            tier_id: item.id,
            tiertype: this.tiertype,
            facture: [4],
            date_debut: this.dd,
            date_fin: this.df,
          };
          query = SIT_TIER_DETAIL;
          result = "sit_tier_detail";

          this.width = "1300px";
          this.orientation = "l";
          break;

        case "cmd_ttc":
          this.listheaders = this.cmdheaders;
          this.title = "Commandes " + this.tier_name + " : " + item.nom;
          query = ALLCMDS;
          this.width = "1400px";
          result = "allcmds";
          w = {
            where: {
              AND: [
                { column: "STATUT_ID", operator: "GTE", value: "7" },
                { column: "TIER_ID", value: item.id },
                { column: "CMD_TYPE", value: this.tiertype == 1 ? 2 : 1 },
                { column: "DATE_CMD", operator: "GTE", value: this.dd },
                { column: "DATE_CMD", operator: "LTE", value: this.df },
              ],
            },
          };

          break;
        case "val_tot_liv":
          w = {
            where: {
              AND: [
                { column: "STATUT_ID", operator: "GTE", value: "11" },
                { column: "TIER_ID", value: item.id },
                {
                  column: "MVM_TYPE",
                  operator: "IN",
                  value: this.tiertype == 1 ? ["1", "17"] : ["3", "23"],
                },
                { column: "MVM_DATE", operator: "GTE", value: this.dd },
                { column: "MVM_DATE", operator: "LTE", value: this.df },
              ],
            },
          };

          this.listheaders = this.mvmheaders;
          if (this.tiertype == 1)
            this.title = "Receptions Fournisseur : " + item.nom;
          else this.title = "Expédition Client : " + item.nom;
          query = ALLMVM;
          result = "allmvm";
          this.width = "1300px";
          this.orientation = "l";

          break;
        case "val_tot_ret":
          w = {
            where: {
              AND: [
                { column: "STATUT_ID", operator: "GTE", value: "11" },
                { column: "TIER_ID", value: item.id },
                { column: "MVM_TYPE", value: this.tiertype == 1 ? "2" : "4" },
                { column: "MVM_DATE", operator: "GTE", value: this.dd },
                { column: "MVM_DATE", operator: "LTE", value: this.df },
              ],
            },
          };

          this.listheaders = this.mvmheaders;
          if (this.tiertype == 1)
            this.title = "Retours Fournisseur : " + item.nom;
          else this.title = "Retours Client : " + item.nom;

          query = ALLMVM;
          result = "allmvm";
          this.width = "1300px";
          this.orientation = "l";

          break;
        case "ttc_fact":
          this.listheaders = this.factheaders;
          this.title = "Factures " + this.tier_name + " : " + item.nom;
          query = ALLFACT;
          result = "allfact";
          w = {
            where: {
              AND: [
                { column: "STATUT_ID", operator: "GTE", value: "14" },
                { column: "TIER_ID", value: item.id },
                { column: "FACT_TYPE", value: this.tiertype == 1 ? 1 : 3 },
                { column: "FACT_DATE", operator: "GTE", value: this.dd },
                { column: "FACT_DATE", operator: "LTE", value: this.df },
              ],
            },
          };
          this.width = "1300px";
          this.orientation = "l";

          break;
        case "ttc_fact_av":
          this.listheaders = this.factheaders;
          this.title = "Factures Avoir " + this.tier_name + " : " + item.nom;
          query = ALLFACT;
          result = "allfact";
          w = {
            where: {
              AND: [
                { column: "STATUT_ID", operator: "GTE", value: "14" },
                { column: "TIER_ID", value: item.id },
                { column: "FACT_TYPE", value: this.tiertype == 1 ? 2 : 4 },
                { column: "FACT_DATE", operator: "GTE", value: this.dd },
                { column: "FACT_DATE", operator: "LTE", value: this.df },
              ],
            },
          };
          this.width = "1300px";
          this.orientation = "l";

          break;
        case "paye_enl":
          this.listheaders = this.payeheaders;
          this.title =
            "Reglement Enlevements " + this.tier_name + " : " + item.nom;
          query = ALLPAYE;
          result = "allpaye";
          w = {
            where: {
              AND: [
                { column: "STATUT_ID", operator: "GTE", value: "17" },
                { column: "TIER_ID", value: item.id },
                { column: "FACTURE", value: "0" },
                { column: "PAYE_DATE", operator: "GTE", value: this.dd },
                { column: "PAYE_DATE", operator: "LTE", value: this.df },
              ],
            },
          };
          this.payeheaders[4].hiden = true;
          this.payeheaders[5].hiden = true;
          this.width = "1300px";
          this.orientation = "l";

          break;
        case "paye_fact":
          this.listheaders = this.payeheaders;
          if (this.tiertype == 1)
            this.title = "Paiements/Encaissements Fournisseur : " + item.nom;
          else this.title = "Encaissements/Paiements Client : " + item.nom;
          this.width = "1300px";
          query = ALLPAYE;
          result = "allpaye";
          w = {
            where: {
              AND: [
                { column: "STATUT_ID", operator: "GTE", value: "17" },
                { column: "TIER_ID", value: item.id },
                { column: "FACTURE", value: "1" },
                { column: "PAYE_DATE", operator: "GTE", value: this.dd },
                { column: "PAYE_DATE", operator: "LTE", value: this.df },
              ],
            },
          };
          this.payeheaders[4].hiden = false;
          this.payeheaders[5].hiden = false;
          this.width = "1300px";
          this.orientation = "l";
          break;

        default:
          break;
      }
      this.$apollo
        .query({
          query: query,
          variables: w,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          this.list = data.data[result];
          if (item.col == s) {
            let l = this.list.filter((elm) => elm.opr == "Encaissement");
            if (l.length > 0) {
              l.sort(this.sortBy("date_opr"));
              this.subtitle =
                " Dernier encaissement le " +
                this.datefr(l[0].date_opr) +
                " Montant : " +
                this.numberWithSpace(l[0].credit.toFixed(2));
            }
          }
          this.isListClose = false;
          this.fl++;
        })
        .catch((error) => {
          this.snackbar_color = "error";
          this.snackbar_text = error.message;
          this.snackbar = true;
        });
    },
    print_sit() {},
    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    closeListForm() {
      this.isListClose = true;
    },
    async OpenDetail(item) {
      let auth =
        (this.act_col == "ttc_fact" &&
          this.$store.state.auth.includes("01014")) ||
        (this.act_col == "ttc_fact_av" &&
          this.$store.state.auth.includes("01015")) ||
        (this.act_col == "ttc_fact" &&
          this.$store.state.auth.includes("01012")) ||
        (this.act_col == "ttc_fact_av" &&
          this.$store.state.auth.includes("01013")) ||
        (this.act_col == "cmd_ttc" &&
          this.$store.state.auth.includes("01005")) ||
        (this.act_col == "cmd_ttc" &&
          this.$store.state.auth.includes("01006")) ||
        (this.act_col == "val_tot_liv" &&
          this.$store.state.auth.includes("01007")) ||
        (this.act_col == "val_tot_liv" &&
          this.$store.state.auth.includes("01009")) ||
        (this.act_col == "val_tot_ret" &&
          this.$store.state.auth.includes("01008")) ||
        (this.act_col == "val_tot_ret" &&
          this.$store.state.auth.includes("01010")) ||
        ((this.act_col == "paye_enl" || this.act_col == "paye_fact") &&
          this.$store.state.auth.includes("01016")) ||
        ((this.act_col == "paye_enl" || this.act_col == "paye_fact") &&
          this.$store.state.auth.includes("01017")) ||
        this.$store.state.isadmin;
      if (auth) {
        if (this.act_col == "ttc_fact" || this.act_col == "ttc_fact_av") {
          this.printfact(item);
        } else if (
          this.act_col == "val_tot_liv" ||
          this.act_col == "val_tot_ret"
        ) {
          this.printMvm(item);
        } else
          await this.$router
            .push({
              name:
                this.act_col == "cmd_ttc" && this.tiertype == 2
                  ? "commandes_c"
                  : this.act_col == "ttc_fact" && this.tiertype == 2
                  ? "facturations_c"
                  : this.act_col == "ttc_fact_av" && this.tiertype == 2
                  ? "facturations_ca"
                  : this.act_col == "val_tot_liv" && this.tiertype == 2
                  ? "livraisons"
                  : this.act_col == "val_tot_ret" && this.tiertype == 2
                  ? "retours_c"
                  : (this.act_col == "paye_enl" ||
                      this.act_col == "paye_fact") &&
                    this.tiertype == 2
                  ? "encaissements"
                  : this.act_col == "cmd_ttc" && this.tiertype == 1
                  ? "commandes_f"
                  : this.act_col == "ttc_fact" && this.tiertype == 1
                  ? "facturations_f"
                  : this.act_col == "ttc_fact_av" && this.tiertype == 1
                  ? "facturations_fa"
                  : this.act_col == "val_tot_liv" && this.tiertype == 1
                  ? "receptions_e"
                  : this.act_col == "val_tot_ret" && this.tiertype == 1
                  ? "retours_f"
                  : (this.act_col == "paye_enl" ||
                      this.act_col == "paye_fact") &&
                    this.tiertype == 1
                  ? "paiements"
                  : "",
              params: {
                cmd: this.act_col == "cmd_ttc" ? item : null,
                facture:
                  this.act_col == "ttc_fact" || this.act_col == "ttc_fact_av"
                    ? item
                    : null,
                mvm:
                  this.act_col == "val_tot_liv" || this.act_col == "val_tot_ret"
                    ? item
                    : null,
                paiement:
                  this.act_col == "paye_enl" || this.act_col == "paye_fact"
                    ? item
                    : null,
                name: this.tiertype == 1 ? "sit_fournisseurs" : "sit_clients",
              },
            })
            .catch(() => {});
      }
      item.document_id = item.id;
      this.doc = item;
    },

    TierChange(item) {
      this.tier = item;
    },
  },
};
</script>
